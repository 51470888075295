@import "../../App.scss";

.alert-modal-container {
  position: fixed;
  margin-left: 33%;
  margin-right: 33%;
  margin-top: $medium-margin;
  width: clamp(400px, 33%, 1000px);
  padding: $small-margin;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $nn-orange;
  border-radius: 0.5rem;
  box-shadow: $shadow-4;
  z-index: 3;
  transition: 1.5s;

  &.hidden {
    transform: translateY(-600%);
    z-index: 1;
  }
}

.alert-modal-close-button-container {
  position: absolute;
  right: 0;
  top: 0;
}
