.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-container {
  background-color: white;
  padding: 2rem;
  min-width: 30rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-close-button-container {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.straight-line {
  width: 100%;
  padding: 0px;
  margin: 0px;
  border: 1px solid black;
}
