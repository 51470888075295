@import "../../App.scss";

.dropdown-container {
  margin-top: $small-margin;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dropdown-header {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;

  input {
    width: 80%;
  }
  .dropdown-extend-button {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-100;
  }
}

.dropdown-items-container {
  width: 100%;
  position: absolute;
  background-color: $grey-50;
  box-shadow: $shadow-4;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 3;

  .dropdown-item {
    padding: 1rem;
    cursor: pointer;
  }
  .dropdown-item:hover {
    background-color: $grey-200;
  }
}
