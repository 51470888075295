@import "../../App.scss";

$defualt_background_color: $grey-100;
$selected_background_color: $nn-orange-muted;
$disabled_background_color: $grey-300;

.pagination-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 3rem 0px 0px 0px; /* Remove margins */
}

.item {
  background-color: $defualt_background_color;
  cursor: pointer;
  border-right: 1px solid black;

  &:last-child {
    border-right: none;
  }
}

.item.selectable-shadow:hover {
  background-color: $selected_background_color;
  transition: 0.5s;

  &.disabled {
    background-color: $disabled_background_color;
  }
}

.page.selected {
  background-color: $selected_background_color;
  margin: 0 2px 0 2px;
}

.prev-button.disabled,
.next-button.disabled {
  background-color: $disabled_background_color;
}

.item {
  width: 100%;
  height: 2rem;
}

.item a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
