@import "../../App.scss";

.panel-header {
  display: flex;
  justify-content: space-between;
}

div.panel {
  border: 1px solid $grey-400;
  box-shadow: $shadow-2;
}

.expand-button {
  cursor: pointer;
}
