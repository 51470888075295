/* colors */

/* grey */
$grey-50: #f8fafc;
$grey-100: #f1f5f9;
$grey-200: #e2e8f0;
$grey-300: #cbd5e1;
$grey-400: #94a3b8;
$grey-500: #64748b;
$grey-600: #475569;
$grey-700: #334155;
$grey-800: #1e293b;
$grey-900: #0f172a;
/* rest of the colors */
$black: #222;
$white: #fff;
$red-light: #f8d7da;
$red-dark: #842029;
$green-light: #adfad7;
$green-dark: #0f5132;
$orange: #d26c18;
$dark-blue: #1a408b;
$yellow-warning: rgb(255, 226, 139);

$nn-green: rgb(166, 208, 0);
$nn-blue: rgb(31, 140, 165);
$nn-blue-muted: rgb(208, 241, 248);
$nn-blue-dark: rgb(30, 97, 112);
$nn-orange: rgb(255, 161, 0);
$nn-orange-muted: rgb(255, 228, 182);
$nn-orange-muted-light: rgb(251, 240, 222);
$nn-grey: rgb(232, 243, 246);
$nn-white: rgb(255, 255, 255);
/* fonts  */
$large-text: 2rem;
$small-text: 0.875rem;
$extra-small-text: 0.7em;
/* rest of the vars */
$backgroundColor: var($grey-50);
$textColor: var($grey-900);
$borderRadius: 0.25rem;
$letterSpacing: 1px;
$transition: 0.3s ease-in-out all;
$max-width: 1120px;
$fixed-width: 600px;
/* marginss*/
$extra-small-margin: 0.5rem;
$small-margin: 1rem;
$medium-margin: 2rem;
$large-margin: 4rem;
/* table colors*/
$table_backround_color: rgb(255, 250, 212);
$table_header_background_color: rgb(111, 111, 111);
$table_header_text_color: white;
$data_row_primary_color: rgb(234, 234, 234);
$data_row_secondary_color: rgb(212, 212, 212);

/* box shadow*/
$shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
  0 10px 10px -5px rgba(0, 0, 0, 0.04);
$shadow-5: -19px 20px 48px -12px rgba(0, 0, 0, 0.75);

$inset-shadow-5: inset -19px 20px 48px -12px rgba(0, 0, 0, 0.75);

body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.main-content-container {
  padding: 3rem;
  display: flex;
  justify-content: center;
  // position: absolute;
  // height: 100%;
  // width: 100%;
  // background: #0f172a;
}

.line {
  padding: 0;
  border: 1px solid black;
  width: 95%;
  align-self: center;
  margin: 1rem 0 1rem 0;
}

.selectable,
.selectable-shadow {
  cursor: pointer;
}

.selectable-shadow:hover {
  box-shadow: $shadow-5;
  background-color: $nn-orange-muted-light;
}

.selectable:hover {
  background-color: $nn-orange-muted-light;
}

* {
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.fixed-width-text {
  font-family: "Montserrat", monospace;
}

.tn {
  font-family: "Montserrat", monospace;
}

button,
.btn {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

button.btn-large {
  border-radius: 10px;
  font-size: 30px;
  padding: 15px 20px;
}

button.disabled {
  background-color: $grey-400;
  cursor: auto;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.informational {
  background-color: $nn-orange-muted;
  border-radius: $small-margin;
}

.loading-icon-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #1976d2;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #0d45a5;
}

/*  
 *   Case List SCSS
*/

.cases-page-container {
  width: clamp(500px, 80%, 1400px);
}

.cases-table {
  display: grid;
  columns: repeat(5, 1fr);
}

.btn-large {
  margin-top: $medium-margin;
}

table {
  width: 100%;
  color: $table_header_text_color;
  margin-top: $medium-margin;
}

thead {
  background: linear-gradient(
    0deg,
    rgba(58, 69, 71, 1) 0%,
    rgba(40, 40, 43, 1) 100%
  );
}

th {
  text-align: left;
}

th,
td {
  padding: 2rem;
}
.data-row {
  transition-duration: 0s;
  transition-timing-function: ease-in-out;
  background-color: $grey-100;
  color: black;

  &:nth-of-type(even) {
    background: $grey-50;
  }
}

.page-counter {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.filter-dropdown-button {
  position: relative;

  &.btn-large {
    margin-top: 0px;
  }
}

.filters-modal {
  padding: 1rem;

  .field-operator-container {
    display: flex;
    flex-direction: row;
  }

  .field-container,
  .operator-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-right: 5%;
  }
  .filters-modal-select {
    margin-top: $small-margin;
    width: 80%;
  }

  .filter-controls {
    margin-top: $small-margin;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .value-select {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
}

.filters-container {
  width: 100%;
  display: flex;
  margin-top: $medium-margin;
}

.filter.btn {
  margin-left: $small-margin !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.close-filter-button {
  margin-left: $extra-small-margin;
  display: flex;
  justify-content: center;
}

.textbox-input-container {
  width: 100%;
  margin-top: $small-margin;

  input {
    width: 100%;
  }
}

.date-input-container {
  margin-top: $small-margin;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .dropdown-container {
    margin-top: $extra-small-margin;
  }

  div {
    margin-right: $small-margin;
  }
}

.item > .item {
  background-color: inherit;
}

.no-cases-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $medium-margin;
}

/*  
 *   New Case SCSS
*/

.new-case-page-container {
  width: clamp(800px, 80%, 1400px);
}

.new-case-upload-container {
  border: 2px solid $grey-200;
  border-radius: $medium-margin;
  padding: $medium-margin;
  box-shadow: $shadow-3;
}

.new-case-page-topbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $large-margin;
}

#csv-upload-cell {
  grid-column: 3 / span 2;
}

#nnid-selection {
  grid-column: 3 / span 2;
}

.topbar-step {
  width: 50%;
  // background-color: rgb(209, 209, 209);
  background: $nn-blue;
  //background: linear-gradient(180deg, $nn-orange 0%, $nn-orange-muted 100%);
  //background: linear-gradient(0deg, $grey-500 40%, $nn-blue 100%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
  padding: 2rem 1rem 2rem 1rem;
  transition: $transition;

  border: 3px solid black;

  border-radius: 1rem 0rem 0rem 1rem;

  &:last-child {
    border-left: 0px;
    border-radius: 0rem 1rem 1rem 0rem;
  }

  &.active {
    z-index: 1;
    box-shadow: $shadow-5;
    background: $nn-blue-dark;
  }
}
/*
 *  CSS for NewCaseUpload.js
*/

.new-case-inputs-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
}

.new-case-inputs-container > label {
  grid-column: 1 / span 2;
}
.new-case-inputs-container > textarea {
  grid-column: 1 / span 4;
}
.new-case-inputs-container > .line {
  grid-column: 1 / span 4;
}

.new-case-inputs-container > * {
  margin: $small-margin 0 $small-margin 0;
}

#nnid-input {
  grid-column: 3 / span 1;
}

.input-error {
  color: red;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
/*
 *  CSS for NewCaseResults.js
*/

.result-group {
  display: flex;
  flex-direction: row;
}

.result-group-text,
.result-group-results {
  width: 50%;
}
.panel {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.panel-body {
  margin-top: 1rem;
}

.results-prov-group {
  display: flex;
  flex-direction: row;
  border-top: 1px solid black;
  padding: 1rem;

  & > div {
    width: 33.333%;
  }

  &:first-child {
    border-top: none;
  }
}

.unable-prov-group {
  display: flex;
  flex-direction: row;
  border-top: 1px solid black;
  padding: 1rem;

  .first-column {
    width: 70%;
  }

  .second-column {
    width: 30%;
  }

  &:first-child {
    border-top: none;
  }
}

li {
  list-style-type: none;
}

.file-upload-list {
  margin-top: 1rem;
  padding: 1rem;
}

.file-upload-error {
  color: red;
}

.result-group-text {
  padding: 1rem;
}

.result-group-results.unable-provisioned {
  padding: 1rem;
}

.result-group-results.unable-provisioned > li {
  text-align: right;
}

.back-modal-control-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.new-case-upload > button {
  margin-top: 2rem;
}

.disabled {
  background-color: rgb(186, 186, 186);
}

.new-case-modal-control-container {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.submit-file-section {
  display: flex;
  justify-content: space-between;
}

div.new-case-upload-grid-item {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
}

/*  
 *  Notification Page SCSS
*/

.notification-page-header {
  margin-bottom: 2rem;
}

.notifications-page {
  width: clamp(800px, 60%, 1500px);
}

.notification {
  position: relative;
  padding: 1rem;
  cursor: pointer;
  transition-duration: 0.5s;
  margin-top: $small-margin;
}

.notification-header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.notification-text {
  width: 80%;
}

.notification.read {
  background-color: $grey-200;
}

.notification-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.notification-date-container {
  display: flex;
  flex-direction: column;
}

.notification-date-header {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.notifications-page-controls {
  display: flex;
  justify-content: space-between;
}

.no-notifications-container {
  margin-top: 2rem;
}

/*
 * Singular Case SCSCC
*/

$section-margin-top: 2rem;

.singular-case-page-container {
  width: clamp(800px, 70%, 1400px);
  display: flex;
  flex-direction: column;
}

.case-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.section {
  padding: 1rem;
}

.section h2 {
  margin-bottom: 1rem;
}

.header-item {
  padding: 1rem 1rem 1rem 0;
  font-size: 1.5rem;
}

.requested-tns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tn-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.tn {
  padding: 0.25rem;
  background-color: white;
}

.tn.selected {
  background-color: lightblue;
}

.tn.error {
  background-color: $nn-orange-muted;
}
.tn.accepted {
  background-color: $green-light;
}

.tn.rejected {
  background-color: $red-light;
}

.requested-tns-controls {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-right: $medium-margin;
    margin-left: $medium-margin;

    &:last-child {
      margin-right: 0rem;
    }
  }
}

.loa {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.5rem 0px 0.5rem 0px;
  border-top: 1px solid black;

  &:first-child {
    border-top: none;
  }
}

.loa-controls {
  button {
    margin-right: $medium-margin;
    &:last-child {
      margin-right: 0rem;
    }
  }
  .btn {
    margin-left: $medium-margin;
  }
}

.comment {
  width: 100%;
  background-color: $white;
  margin-top: 1rem;
  border: 1px solid $grey-400;
  padding: 1rem;
  border-radius: 0.5rem;

  &.them {
    background-color: $grey-100;
  }

  &.system {
    background-color: $grey-300;
  }

  box-shadow: $shadow-1;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  border-bottom: 1px dashed $grey-400;
}

.comment-by {
  font-weight: bold;
}

.comments-input {
  margin-top: $small-margin;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    margin-top: $small-margin;
  }
}

textarea {
  position: relative;
  max-width: 100%;
  min-width: 100%;
  min-height: 5rem;
}

.accept-tns-control-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.tn-accept-reject-container {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.singular-case-top {
  display: flex;
  justify-content: space-between;

  .singular-case-options-container .options-icon {
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 2rem;
    background-color: $grey-100;
    transition: $transition;

    &:hover {
      cursor: pointer;
    }

    &.active {
      transform: rotate(90deg);
    }
  }
}

.singular-case-options-dropdown-option {
  background-color: $grey-100;
  padding: 2rem;
  transition: $transition;
  -webkit-box-shadow: 20px 11px 38px -11px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 20px 11px 38px -11px rgba(0, 0, 0, 0.5);
  box-shadow: 20px 11px 38px -11px rgba(0, 0, 0, 0.5);

  &.enabled:hover {
    background-color: $grey-300;
    cursor: pointer;
  }
  &.disabled {
    background-color: $grey-400;
  }
}

/*
 * Error Page SCSS
*/

$margin-top: 2rem;

.error-page-container {
  width: clamp(600px, 60%, 1400px);
}

.reason {
  margin-top: $margin-top;
}

a.back-button {
  margin-top: $margin-top;
}

/*
 * Login Page SCSS
*/

$salesforce-blue: #1798c1;

.login-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.login-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
  align-self: center;
  margin-top: $small-margin;
  background-color: $nn-blue;
  padding: $medium-margin;

  &:hover {
    box-shadow: $shadow-4;
  }
}

.salesforce-logo {
  height: 5rem;
  width: 5rem;
  color: white;
}

/*
 * Sidebar SCSS
*/

div.sidebar {
  height: 100%;
  position: fixed;
  padding-top: 40px;
  background-color: $nn-blue;
  transition: 1s;
  width: clamp(500px, 25%, 700px);

  &.hidden {
    transform: translate(-100%);
  }
}

.sidebar {
  z-index: 2;
}

.sidebar-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.sidebar-section-title {
  margin: 1rem;
}

$background-color-1: rgb(173, 216, 230);
$background-color-2: rgb(236, 246, 246);

.sidebar-option {
  display: inline-block;
  padding: 2rem;
  font-size: large;
  text-align: center;
  color: white;
  background: rgba(31, 140, 165, 0.5); /* default color */
  border: 0px;
  transition: $transition;
  cursor: pointer;

  &:hover {
    background-color: $nn-blue-muted;
  }

  &.provisioner-select:hover {
    background-color: transparent;
  }

  &.provisioner-select {
    color: black;
  }
}

.sidebar.visible {
  -webkit-box-shadow: 20px 11px 38px -11px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 20px 11px 38px -11px rgba(0, 0, 0, 0.5);
  box-shadow: 20px 11px 38px -11px rgba(0, 0, 0, 0.5);
}

/*
 * TopBar SCSS
*/

@keyframes bell-expand {
  to {
    width: 4rem;
    height: 4rem;
  }
}

.rounded.active {
  animation-name: bell-expand;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: east-in-out;
  animation-delay: 0s;
  animation-direction: forwards;
  animation-play-state: running;
  animation-fill-mode: forwards;
}

.leftside .rounded.active .menu-icon {
  transition-duration: 1s;
}

.leftside .rounded.active .menu-icon {
  transform: rotate(90deg);
}

.topbar-container {
  position: sticky;
  top: 0;
  z-index: 100; /*Needs to be so high because MUI FAB is 1050*/
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: green;
  height: 6rem;
  align-items: center;
  background: rgb(58, 69, 71);
  background: linear-gradient(
    0deg,
    rgba(58, 69, 71, 1) 0%,
    rgba(40, 40, 43, 1) 100%
  );
  -webkit-box-shadow: 0px 11px 38px -11px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 11px 38px -11px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 11px 38px -11px rgba(0, 0, 0, 0.5);

  .middle {
    position: absolute;
    left: 40%;
    right: 40%;
    display: flex;
    justify-content: center;
  }

  .leftside {
    margin-left: 4rem;
  }

  .rightside {
    margin-right: 4rem;
  }

  .leftside {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .rightside {
    height: 100%;
    width: 10rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  div:hover {
    box-shadow: 2rem;
  }
}

.anywhere-else {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
}

.notifications-dropdown {
  overflow-y: scroll;
}

.dropdown-controls {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dropdown-list {
  position: relative;

  list-style-type: none;
  padding: 0;
  width: 100%;

  .notification-modal {
    margin: 0;
    padding: 1rem;
    background-color: white;
    cursor: pointer;
  }

  .notification-modal:hover {
    background-color: lightgray;
    font: inherit;
  }
}

.notification-header {
  background-color: transparent;
}

button.notification-read-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.netnumber-logo {
  width: 210px;
  position: fixed;
  left: 170px;
}

.notification-modal {
  position: relative;
}

.notifications {
  position: relative;

  .notifications-alert {
    position: absolute;
    bottom: -10px;
    left: -10px;
    background-color: $nn-orange;
    border-radius: 50%;
    padding: 0.25rem;
  }
}
